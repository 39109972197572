/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
#header-wrap {
  position: relative;
  background: rgba(255, 255, 255, 0.85);
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #666;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #666;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
}
.font-weight-strong {
  font-weight: 600 !important;
}

/* --------- asc styles --------- */
#header {
  float: none;
  a.logo {
    float: left;
    margin-top: 15px;
    margin-bottom: 15px;
    border: 0;
    width: 80%;
    max-width: 650px;
    img {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      width: auto;
      height: auto;
      max-width: 40px;
    }
    span.big,
    span.small {
      display: inline;
      font-size: 1.5em;
      font-weight: 500;
      color: #555;
      line-height: 1em;
      vertical-align: middle;
    }
    span.big {
      font-weight: 700;
      font-family: 'Roboto Slab', 'Open Sans', Verdana;
    }
    span.small {
      display: none;
      margin-top: 0;
      font-size: 16px;
      font-family: var(--font-family);
      font-weight: normal;
    }
  }
  #support {
    p {
      float: right;
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      display: inline-block;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.65);
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    span.phone {
      padding-right: 8px;
      border-right: 1px dotted #333;
    }
    a.help-center {
      // display: inline-block;
      margin: 0 10px 0 0;
      font-weight: normal;
      color: #555;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
}

#header-wrapper {
  background: #fff url(../images/header-bg2.jpg) no-repeat 50% 0 / cover;
  &.food {
    background: #fff url(../images/texas-food-bg2.jpg) no-repeat 0% 70% / cover;
  }
}

#banner-wrap {
  border-bottom: 1px solid #ebebeb;
  #banner {
    h1 {
      color: #333;
    }
    #pitch {
      background: rgba(255, 255, 255, 0.9);
      border: 1px solid #f93;
    }
    ul.star > li:before {
      color: $theme-primary;
    }
  }
  .btn-width {
    min-width: 220px;
  }
}

#other-courses {
  background: #f7f5f3;
  border-bottom: 1px solid #ebebeb;
  .card {
    border-top: 3px solid $theme-primary;
    padding: 20px;
  }
  .learn-more a {
    color: #666;
  }
  .best-value {
    background: $theme-primary;
    & + .card {
      border-top: none;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }
}

.section {
  background: #fff url(../images/section-bg.jpg) no-repeat top right;
  border: 1px solid #ccc;
  border-top: 3px solid $theme-primary;
}

.action {
  margin: 20px auto;
  max-width: 450px;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .action {
    height: 75px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img {
      display: block;
      float: left;
      max-width: 80px;
      margin-right: 15px;
    }
    span.big {
      display: inline-block;
      font-size: 1.9em;
    }
    span.small {
      display: inline-block;
    }
  }
}
// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
  #header a.logo {
    span.small {
      font-size: 18px;
    }
  }
  #header-wrap {
    border-top: 3px solid $theme-primary;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    width: 60%;
    span.small {
      font-size: 18px;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #header-wrapper {
    background: #fff url(../images/header-bg2.jpg) no-repeat 50% 75%;
  }
}
